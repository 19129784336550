import $ from 'jquery'; // eslint-disable-line import/no-extraneous-dependencies
import React from 'react';
import { render } from 'react-dom';
import bootstrapSite from '../app/bootstrapSite';
import createObserver from '../utilities/createObserver';
import api from '../common/services/api';

import EditableBanner from '../common/components/EditableBanner';

bootstrapSite();

try {
    function handlePromoTakeover() {
        const homepageTakeover = document.getElementById('promo-takeover');
    
        if (homepageTakeover) {
            const siteHeader = document.getElementById('site-header');
        
            if(siteHeader) {
                siteHeader.classList.add('promo-takeover');
            }
        }
    }
    
    handlePromoTakeover();
    
    window.addEventListener(
        "monetate-homepage-takeover",
        handlePromoTakeover
    );
} catch (error) {
    console.error(error)
}

// For IE10 show first image
// of carousel only

if (document.documentMode === 10) {
    $('.carousel > article').first().css('opacity', '1');
    $('.carousel > article div > img').first().css('top', '-75%');
}

window.addEventListener('load', () => {
    const $parentColl = $('#homepage-collections-list li');
    const $coll = $('#collToggle');
    const sliceNum = 7;

    $coll.slideToggleContent($parentColl, sliceNum, 500, 'swing', { more: 'Show All', less: 'Close' }, () => {
        $(window).trigger('scroll.odimg');
    });

    const sailThruCallback = (entries, observer) => {
        entries.forEach((entry) => {
            if (entry.isIntersecting || entry.intersectionRatio > 0) {
                import(/* webpackChunkName: "sailThru" */ '../components/sailThru')
                    .then((sailThru) => {
                        sailThru.default();
                    });
                observer.unobserve(entry.target);
            }
        });
    };

    const sailThruContainer = document.querySelectorAll('.offers-carousel');

    createObserver([...sailThruContainer], sailThruCallback);

    const bannerWrapper = document.getElementById('bannerWrapper');

    api.getEditableBanner()
        .then((data) => {
            if (data.status) {
                const {
                    link, linkText, title, backgroundColour
                } = data.data;

                render(<EditableBanner
                    link={link}
                    linkText={linkText}
                    title={title}
                    backgroundColour={backgroundColour}
                    adjustMenu={() => {}}
                />, bannerWrapper);
            }
        });
});
