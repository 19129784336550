import React, { useState } from 'react';
import Cross from '../icons/Cross';

interface BannerProps {
    title: string,
    backgroundColour: string,
    adjustMenu: Function,
    link?: string,
    linkText?: string;
}

const EditableBanner = ({
    link, linkText, title, backgroundColour, adjustMenu
}: BannerProps) => {
    const [close, setClose] = useState(false);

    const buttonHandler = () => {
        setClose(true);
        adjustMenu();
    };

    return (
        <>
            { !close && (
                <div className={`c-editableBanner c-editableBanner--${backgroundColour}`}>
                    <div className="flex-column-center-s flex-column-center-m flex-row-center-l c-editableBanner__content">
                        <h1 className="c-editableBanner__title">{title}</h1>
                        { link && linkText && (
                            <a className="c-editableBanner__link" href={link}>{linkText}</a>
                        )}
                        <button className="c-editableBanner__button" onClick={() => buttonHandler()} type="button">
                            <Cross className="c-modal__crossIcon" />
                        </button>
                    </div>
                </div>
            )}
        </>
    );
};

export default EditableBanner;
